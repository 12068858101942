exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-index-js": () => import("./../../../src/pages/archives/index.js" /* webpackChunkName: "component---src-pages-archives-index-js" */),
  "component---src-pages-canary-index-js": () => import("./../../../src/pages/canary/index.js" /* webpackChunkName: "component---src-pages-canary-index-js" */),
  "component---src-pages-changes-archive-js": () => import("./../../../src/pages/changes/archive.js" /* webpackChunkName: "component---src-pages-changes-archive-js" */),
  "component---src-pages-changes-index-js": () => import("./../../../src/pages/changes/index.js" /* webpackChunkName: "component---src-pages-changes-index-js" */),
  "component---src-pages-changes-significant-archive-js": () => import("./../../../src/pages/changes/significant/archive.js" /* webpackChunkName: "component---src-pages-changes-significant-archive-js" */),
  "component---src-pages-changes-significant-index-js": () => import("./../../../src/pages/changes/significant/index.js" /* webpackChunkName: "component---src-pages-changes-significant-index-js" */),
  "component---src-pages-en-fundamentals-index-js": () => import("./../../../src/pages/en/fundamentals/index.js" /* webpackChunkName: "component---src-pages-en-fundamentals-index-js" */),
  "component---src-pages-en-guides-index-js": () => import("./../../../src/pages/en/guides/index.js" /* webpackChunkName: "component---src-pages-en-guides-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-fundamentals-index-js": () => import("./../../../src/pages/ja/fundamentals/index.js" /* webpackChunkName: "component---src-pages-ja-fundamentals-index-js" */),
  "component---src-pages-ja-guides-index-js": () => import("./../../../src/pages/ja/guides/index.js" /* webpackChunkName: "component---src-pages-ja-guides-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-since-js": () => import("./../../../src/pages/since.js" /* webpackChunkName: "component---src-pages-since-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-translations-index-js": () => import("./../../../src/pages/translations/index.js" /* webpackChunkName: "component---src-pages-translations-index-js" */),
  "component---src-templates-layout-changelog-js": () => import("./../../../src/templates/layout-changelog.js" /* webpackChunkName: "component---src-templates-layout-changelog-js" */),
  "component---src-templates-layout-default-full-js": () => import("./../../../src/templates/layout-default_full.js" /* webpackChunkName: "component---src-templates-layout-default-full-js" */),
  "component---src-templates-layout-default-js": () => import("./../../../src/templates/layout-default.js" /* webpackChunkName: "component---src-templates-layout-default-js" */),
  "component---src-templates-layout-fundamentals-primary-js": () => import("./../../../src/templates/layout-fundamentals-primary.js" /* webpackChunkName: "component---src-templates-layout-fundamentals-primary-js" */),
  "component---src-templates-layout-guide-article-js": () => import("./../../../src/templates/layout-guide-article.js" /* webpackChunkName: "component---src-templates-layout-guide-article-js" */),
  "component---src-templates-layout-guide-primary-js": () => import("./../../../src/templates/layout-guide-primary.js" /* webpackChunkName: "component---src-templates-layout-guide-primary-js" */),
  "component---src-templates-layout-products-primary-js": () => import("./../../../src/templates/layout-products-primary.js" /* webpackChunkName: "component---src-templates-layout-products-primary-js" */)
}

